import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"



const Content = () => {


    const data = useStaticQuery(graphql`
    query ContentQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___order], order: ASC }) {
      edges {
        node {
          id
          excerpt
          slug
          frontmatter {
            title
            description
            order
            embeddedImagesLocal {
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
            }
            embeddedImagesRemote {
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
            }
          }
          timeToRead
        }
      }
    }
    
  }
  `)



const posts = data.allMdx.edges

    return (
    <>

        <div className="w-100 cf ls-sm">
            <div className="mw-1400 center flex items-center" >
                 <Link className="dib link black-900 flex items-center mr2" to="/" >
                 <svg className="h24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>                 
                 </Link>
                <Link className="dib link black-900 flex items-center" to="/menu-page" >
                 <svg className="h24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" /></svg>
                 <div className="pl05 f6 fw5 dib black-900">grid view</div>
                </Link>
            </div>
            <hr/>
        </div>


        {posts.map(({ node, i }) => {
          const title = node.frontmatter.title || node.fields.slug
          const excerpt = node.frontmatter.description ? node.frontmatter.description.substring(0, 160) + '...' : node.excerpt
          const imageToUse = node.frontmatter.embeddedImagesLocal ? node.frontmatter.embeddedImagesLocal[0] : node.frontmatter.embeddedImagesRemote 
         
          return (

            <div className="w-100 cf bb b-black-20 mw-800" key={node.slug}>
                  <Link className="w-100 db cf pb1 pb2-m link pointer" to={`/${node.slug}`}>

                    <div className="w-100 center pt2 flex flex-column flex-row-m ">
                      <div className="w-100 cf pv1 ">
                          <div className="f8 orange-900 ttu pb1 ls-md">{node.frontmatter.category} </div>
                          <div className="f5 f4-m fw5 black  pb1">{title} </div>
                          <div className="f6 fw4 lh-copy black-700 pb1 pr0 pr2-m">{excerpt}</div>
                          <div className="f8 fw4 i ls-sm orange-900">{node.timeToRead > 0 ? node.timeToRead + ' min read' : '' } </div>
                      </div>
                      <div className="w-100 flex items-center justify-center mw-9-m">
                        <div className=" br-100 w8 h8 overflow-hidden ba b-black-10 bw3 obj-cover">
                          <GatsbyImage alt="..." image={getImage(imageToUse)} />
                        </div>
                      </div>

                    </div>
                  </Link>
            </div>
       
          )
        })}

     
    </>
    )
  }
  
  export default Content